import './App.scss'
import { useEffect } from 'react'
import AOS from 'aos'

import SharedLayout from './Util/SharedLayout/SharedLayout'
import Home from './Pages/Home/Home'
import Team25 from './Pages/Teams/Team25'
import Team23 from './Pages/Teams/Team23'
import Team21 from './Pages/Teams/Team21'
import Team19 from './Pages/Teams/Team19'
import Partners from './Pages/Partners/Partners'
import ComingSoon from './Pages/ComingSoon/ComingSoon'

import { Route, BrowserRouter, Routes } from 'react-router-dom'
import AboutUs from './Pages/AboutUs/AboutUs'
import Challenges from './Pages/Challenges/Challenges'
import Cars from './Pages/Cars/Cars'
import Contact from './Pages/Contact/Contact'
// import CrowdFund from './Pages/CrowdFund/CrowdFund'
// import Hydrogen from './Pages/Hydrogen/Hydrogen'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from './Pages/PrivacyPolicy/TermsandConditions'
import TermsAndConditionsNL from './Pages/PrivacyPolicy/TermsandConditionsNL'
import ScrollToTop from './Util/ScrollToTop/ScrollToTop'
import CarReveal from './Pages/CarReveal/CarReveal'
import LiveStream from './Pages/LiveStream/livestream'
import RecruitmentPage from './Pages/RecruitmentPage/RecruitmentPage'
import FAQ from './Pages/FAQ/FAQ'

function App() {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/team25" element={<Team25 />} />
            <Route path="/team23" element={<Team23 />} />
            <Route path="/team21" element={<Team21 />} />
            <Route path="/team19" element={<Team19 />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/blogs" element={<ComingSoon />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="*" element={<ComingSoon error={true} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions-en" element={<TermsAndConditions />} />
            <Route path="/terms-and-conditions-nl" element={<TermsAndConditionsNL />} />
            <Route path="/carreveal2023" element={<CarReveal />} />
            <Route path="/become" element={<RecruitmentPage />} />
            <Route path="/faq" element={<FAQ />} />
          </Route>
          {/* <Route path="/hydrogen" element={<Hydrogen />} */}
          {/* /> */}
          <Route path="/livestream" element={<LiveStream />} />
          
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
